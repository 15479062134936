<template>
  <div class="view pa24">
    <p class="fs9 cA1 mb20 fwbold">活动内容</p>
    <div class="bgf7fa pa15 mb30">
      <div class="fs8 w600 d-flex mb20 mr20">
        <div class="w120 text-align-right lh1">活动名称：</div>
        <p class="lh1">{{ formInfo.name }}</p>
      </div>
      <div class="fs8 w600 d-flex mb20 mr20">
        <div class="w120 text-align-right lh1">活动日期：</div>
        <p class="lh1">{{ formInfo.startTime }}-{{ formInfo.endTime }}</p>
      </div>
      <div class="fs8 w600 d-flex mb20 mr20">
        <div class="w120 text-align-right lh1">活动状态：</div>
        <p class="lh1" v-if="formInfo.status == 1">未开始</p>
        <p class="lh1" v-else-if="formInfo.status == 2">进行中</p>
        <p class="lh1" v-else-if="formInfo.status == 3">已结束</p>
      </div>
      <div class="fs8 w600 d-flex align-items-center mb20 mr20">
        <div class="w120 text-align-right lh1">报名人数限制：</div>
        <div class="lh1">{{ formInfo.userNum }}</div>
      </div>
      <div class="fs8 w600 d-flex align-items-center mr20">
        <div class="w120 text-align-right lh1">已报名数：</div>
        <div class="lh1">{{ formInfo.joinedNum }}</div>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center">
      <el-date-picker
        v-model="pickerValue"
        class="mr10 "
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      <el-button type="primary" @click="queryVisitorsData">搜索</el-button>
    </div> -->
    <div class="flex-a-b-c mb20">
      <p class="fs9 cA1 fwbold"> 活动报名详情</p>
      <el-button type="primary" :loading="loadingBtn" @click="outPutActivityRegisterList" :disabled="tableData.length <= 0">导出详情</el-button>
    </div>
    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage" :loading="loading" :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
        <el-table-column align="center"
          style="-webkit-align-items: center;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;-webkit-box-orient: vertical;"
          prop="companyName" label="企业名称" show-overflow-tooltip width="100px"> </el-table-column>
        <el-table-column align="center"
          style="-webkit-align-items: center;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;-webkit-box-orient: vertical;"
          prop="name" label="用户名" show-overflow-tooltip width="100px"> </el-table-column>
        <el-table-column align="center"
          style="-webkit-align-items: center;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;-webkit-box-orient: vertical;"
          prop="phone" label="联系方式" show-overflow-tooltip width="120px"> </el-table-column>
        <div v-for="(item, index) in options" :key="item.id">
          <el-table-column align="center" v-if="item.id == ('SINGLE' + (index - 1))" :label="item.name"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <span> {{ scope.row['single' + (index)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == ('MULTIPLE' + (index - 1))" prop="multiple"
            :label="item.name" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DROP_DOWN' + (index - 1)" prop="dropDown"
            :label="item.name" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'TEXTAREA' + (index - 1)" prop="textarea"
            :label="item.name" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'SCOURE' + (index - 1)" prop="scoure" :label="item.name"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'IMAGE' + (index - 1)" prop="image" :label="item.name"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image v-if="scope.row.image.length > 0" style="width: 36px;height:36px" :src="scope.row.image[0]"
                :preview-src-list="scope.row.image">
              </el-image>
              <span v-if="scope.row.image.length > 0">x{{ scope.row.image.length }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'GPS' + (index - 1)" prop="gps" :label="item.name"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'USERINFO' + (index - 1)" prop="userInfo"
            :label="item.name" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-for="(info, index) in scope.row.userInfo" :key="index">
                <span>{{ info.answer }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DATEPICKER' + (index - 1)" prop="datepicker"
            :label="item.name" show-overflow-tooltip></el-table-column>
        </div>
        <el-table-column align="center" prop="datepicker" label="是否退出报名" width="100px" v-show="delayedSwitch"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.status == 1 ? '是' : '否' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createdTime" label="创建时间" show-overflow-tooltip> </el-table-column>
        <el-table-column align="center" prop="cancelTime" label="退出时间" v-if="delayedSwitch" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="datepicker" label="操作" width="100px" v-if="delayedSwitch"
          show-overflow-tooltip fixed="right">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 1">已退出报名</p>
            <p class="corange hover_pointer" v-if="scope.row.status == 0" @click="activityExit(scope.row)">退出报名</p>
          </template>
        </el-table-column>
      </template>

    </commonTable>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {
  activityRegisterList,
  activityEventDetails,
  activityExit,
  outPutActivityRegisterList
} from "@/api/companyManage.js";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      currentDateIndex: 7,
      dialogTableVisible: false,
      companyInfo: '',
      aeId: '',
      options: [
        { id: '', name: "全部" }
      ],
      pageNum: 1,
      form: '',
      delayedSwitch: false,
      formInfo: '',//
      loadingBtn: false,// 
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.aeId = this.$route.query.aeId
  },
  mounted() {
    // this.queryCompanyStaff();
    this.getTableData();
    this.getDetail()
  },
  methods: {
    /**@method 导出活动详情 */
    outPutActivityRegisterList() {
      this.$message.info("导出中...")
      this.loadingBtn = true;
      outPutActivityRegisterList({
        aeId: this.aeId
      }).then(res => {
        this.loadingBtn = false;
        if (res.code == 200) {
          let link = document.createElement('a');
          link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
          link.click();
        } else {
          this.$message.error(res.message);
        }
      }).catch(err => {
        this.loadingBtn = false;
        if (err.code != 201) {
          this.$message.error(err.message);
        }
      })
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      var result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      return result;
    },
    getDetail() {
      activityEventDetails({
        aeId: this.aeId
      }).then(res => {
        this.form = res.data.form;
        this.formInfo = res.data.activityEvent
        this.form.detailRequests.forEach((item, index) => {
          this.options.push({ id: item.arfType + index, name: item.title });
        });
      });
    },
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        aeId: this.aeId
      }
      activityRegisterList(data)
        .then((res) => {
          if (res && res.data.pageInfo.list[0] != null) {
            this.loading = false;
            this.tableData = res.data.pageInfo.list;
            this.total = res.data.pageInfo.total;

            this.tableData.forEach(item => {
              if (item.datepicker && item.datepicker != "") {
                const datepicker = JSON.parse(item.datepicker);
                if (datepicker && datepicker[0]) {
                  item.datepicker = datepicker[0].answer;
                }
              }
              if (item.userInfo && item.userInfo != "") {
                const userInfo = JSON.parse(item.userInfo);
                item.userInfo = userInfo;//.map(function(user){return user.answer}).join("\n");
              }

              if (item.image && item.image != "") {
                const image = JSON.parse(item.image);
                item.image = image[0].answer;
              }

              if (item.scoure && item.scoure != "") {
                const scoure = JSON.parse(item.scoure);
                item.scoure = scoure.map(function (sc) {
                  return parseInt(sc.answer) + 1
                }).join("星 ") + "星";
              }

              if (item.textarea && item.textarea != "") {
                const textarea = JSON.parse(item.textarea);
                item.textarea = textarea[0].answer;
              } else {
                item.textarea = "";
              }

              //const dropDown = JSON.parse(item.dropDown);
              if (item.single && item.single != "") {
                try {
                  //key=123
                  const singTemp = item.single.split("=");//[key,123]
                  const single = JSON.parse(singTemp[0]);//key
                  const result = single.find(function (sc) {
                    return sc.answer == true
                  });
                  item.single1 = result ? result.title : '';

                  const single2 = JSON.parse(singTemp[1]);
                  const result2 = single2.find(function (sc) {
                    return sc.answer == true
                  });
                  item.single2 = result2 ? result2.title : '';
                } catch (error) {
                  //-console.log(error)
                }
              } else {
                item.single = "";
              }

              if (item.multiple && item.multiple != "") {
                const multiple = JSON.parse(item.multiple);
                item.multiple = multiple.map(function (sc) {
                  return sc.title
                }).join("/");
              } else {
                item.multiple = "";
              }
            })
            this.delayedSwitch = true
          } else {
            this.tableData = [];
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          //-console.log(err);
        });
    },
    activityExit(item) {
      let than
      this.$prompt('请输入退出备注', '退出报名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then((value) => {
        let data = {
          arId: item.arId,
          note: value,
        }
        activityExit(data)
          .then(res => {
            if (res.code == 200) {
              this.$message.success('成功取消')
              this.getTableData()
            }
          })
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.loading = true;
      this.getTableData();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    searchPage() {
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped></style>